import React from 'react';
import { AppHeader } from '../common/AppHeader';
import { AppManager } from '../data/AppManager';

export class LoadingPage extends React.Component<{app: AppManager}> {
    render() {
        return (
            <>
                <AppHeader title='Loading...' app={this.props.app}></AppHeader>
                <main className='container-max-width'>
                    <p>Loading apps...</p>
                </main>
            </>
        );
    }
}