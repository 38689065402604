import React from 'react';
import { AppHeader } from '../common/AppHeader';
import { AppManager } from '../data/AppManager';

export class ErrorPage extends React.Component<{app: AppManager, children?: any}> {
    render() {
        return (
            <>
                <AppHeader title='Loading...' app={this.props.app}></AppHeader>
                <main className='container-max-width m-m'>
                    {this.props.children || <p>An error occurred while loading the apps.</p>}
                </main>
            </>
        );
    }
}