import React from "react";

import './Fields.css';

export class Input extends React.Component<{
    id?: string,
    className?: string,
    type?: string,
    value?: string,
    disabled?: boolean,
    placeholder?: string,
    maxLength?: number,
    autocomplete?: string,
    onChange?: (value: string, e: any) => void,
    onBlur?: (value: string, e: any) => void
}, {value: string}> {

    constructor(props: any) {
        super(props);
        this.state = {value: this.props.value || ''};
        this.handleChange = this.handleChange.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
    }

    private handleChange(e: any) {
        this.setState({value: e.target.value});
        if (this.props.onChange) {
            this.props.onChange(e.target.value, e);
        }
    }

    private handleBlur(e: any) {
        if (this.props.onBlur) {
            this.props.onBlur(e.target.value, e);
        }
    }

    render() {
        return (
            <input id={this.props.id} className={this.props.className}
                type={this.props.type || 'text'}
                value={this.state.value}
                disabled={this.props.disabled}
                placeholder={this.props.placeholder}
                maxLength={this.props.maxLength}
                autoComplete={this.props.autocomplete}
                onChange={this.handleChange}
                onBlur={this.handleBlur} />
        );
    }
}

export class ComboBox extends React.Component<{
    options: string[] | OptionWithID[],
    id?: string,
    className?: string,
    value?: string,
    disabled?: boolean,
    placeholder?: string,
    autocomplete?: string,
    onChange?: (value: string, e: any) => void,
    onBlur?: (value: string, e: any) => void
}, {
    options: OptionWithID[],
    value: string
}> {

    constructor(props: any) {
        super(props);
        const safeOpts: OptionWithID[] = this.props.options.map((v) => {
            return typeof v === 'string'? {id: v, name: v} : v;
        });
        this.state = {
            options: safeOpts,
            value: this.props.value || ''
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
    }

    private handleChange(e: any) {
        this.setState({value: e.target.value});
        if (this.props.onChange) {
            this.props.onChange(e.target.value, e);
        }
    }

    private handleBlur(e: any) {
        if (this.props.onBlur) {
            this.props.onBlur(e.target.value, e);
        }
    }

    render() {
        return (
            <select id={this.props.id}
                className={this.props.className}
                placeholder={this.props.placeholder}
                autoComplete={this.props.autocomplete}
                value={this.state.value}
                disabled={this.props.disabled}
                onChange={this.handleChange}
                onBlur={this.handleBlur} >
                {
                    this.state.options.map((v) => {
                        return <option key={v.id} value={v.id}>{v.name}</option>;
                    })
                }
            </select>
        );
    }
}

export interface OptionWithID {
    id: string,
    name: string
}