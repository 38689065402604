import React from 'react';
import { AppHeader } from '../common/AppHeader';
import { Button } from '../common/Button';
import { Input } from '../common/Fields';
import { NavTitle } from '../common/NavTitle';
import { Section } from '../common/Section';
import { AppManager } from '../data/AppManager';

export class ProfilePage extends React.Component<{app: AppManager}> {
    constructor(props: any) {
        super(props);
        this.logout = this.logout.bind(this);
    }
    render() {
        return (
            <>
                <AppHeader title='Profile' app={this.props.app}></AppHeader>
                <main className='container-max-width'>
                    <NavTitle link='/'>Home</NavTitle>
                    {this.getUserDetails()}
                </main>
            </>
        );
    }

    getUserDetails(): JSX.Element {

        // No user (not logged in)
        const user = this.props.app.getUser();
        if (!user) {
            return <p>You are not logged in.</p>;
        }

        let greeting: string = user.displayName || user.email || '';
        if (greeting) greeting = ' ' + greeting;
        greeting = 'Welcome' + greeting + '.';

        return (
            <>
                <p>{greeting}</p>
                <Section title='About You' id='about-you'>
                    <div className='fieldset'>
                        <div className='field medium'>
                            <label htmlFor='user-name'>Name:</label>
                            <Input className='input' id='user-name' value={user.displayName || ''} disabled />
                        </div>
                        <div className='field medium'>
                            <label htmlFor='user-email'>Email:</label>
                            <Input className='input' id='user-email' value={user.email || ''} disabled />
                        </div>
                    </div>
                </Section>
                <Button type='primary' icon='logout' onClick={this.logout} title='Log out of Devon McGrath Apps'>Logout</Button>
            </>
        );
    }

    logout() {
        this.props.app.logout();
    }
}