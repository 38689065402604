import React from 'react';

import './Section.css';

export class Section extends React.Component<
    {title: string, id?: string, className?: string, children?: any}
> {
    render() {
        const c = 'app-section ' + (this.props.className || '');
        return (
            <section className={c} aria-labelledby={this.props.id}>
                <h2 id={this.props.id} className='app-section-header'>{this.props.title}</h2>
                {this.props.children}
            </section>
        );
    }
}