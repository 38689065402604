import React from 'react';
import { AppHeader } from '../common/AppHeader';
import { AppManager } from '../data/AppManager';

export class NotLoggedInPage extends React.Component<{app: AppManager}> {
    render() {
        return (
            <>
                <AppHeader title='Not Logged In' app={this.props.app}></AppHeader>
                <main className='container-max-width'>
                    <p>You are not logged in. Please <a href={AppManager.LOGIN_URL}>login</a>.</p>
                </main>
            </>
        );
    }
}