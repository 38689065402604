import React from 'react';
import { AppHeader } from '../common/AppHeader';
import { Section } from '../common/Section';
import { AppManager } from '../data/AppManager';

export class HomePage extends React.Component<{app: AppManager}> {
    render() {
        return (
            <>
                <AppHeader title='Home' app={this.props.app}></AppHeader>
                <main className='container-max-width'>
                    <p>There is much to do; the work will begin soon.</p>
                    <Section title='Core' id='main-cards'>
                        <p>TODO</p>
                    </Section>
                    <Section title='Games' id='games-cards'>
                        <p>TODO</p>
                    </Section>
                </main>
            </>
        );
    }
}