import { FirebaseApp, initializeApp } from 'firebase/app';
import { getAuth, Auth, onAuthStateChanged, signOut, User } from 'firebase/auth';
import { Functions, getFunctions } from 'firebase/functions';
import {
    getFirestore,
    doc,
    getDoc,
    setDoc,
    updateDoc,
    serverTimestamp
} from 'firebase/firestore';
import { AppUser } from '../models/core';

const firebaseConfig = {
    apiKey: "AIzaSyChF_UsHnuhQhpr7q3kkzJIN2l1eZl_IfA",
    authDomain: "devon-mcgrath.firebaseapp.com",
    databaseURL: "https://devon-mcgrath.firebaseio.com",
    projectId: "devon-mcgrath",
    storageBucket: "devon-mcgrath.appspot.com",
    messagingSenderId: "861382139846",
    appId: "1:861382139846:web:0e0c6bd33db56dbd6f9cc7",
    measurementId: "G-LN2KS4R4QJ"
};;

/**
 * Wraps a {@link FirebaseApp} object and gets the user information.
 */
export class AppManager {

    /** The URI of the default user profile image. */
    public static DEFAULT_PROFILE_PICTURE = '/img/profile_placeholder.png';

    /** The URI of the login page. */
    public static LOGIN_URL = '/login.html';

    private app: FirebaseApp;

    private auth: Auth;

    private functions: Functions;

    private isAuthReady: boolean = false;

    private user: User | null = null;

    private onAuthReady?: (app: AppManager, user: User | null) => void;

    constructor(config?: object) {
        const c = config ? config : firebaseConfig;
        const app = initializeApp(c);
        this.app = app;
        this.auth = getAuth(this.app);
        this.functions = getFunctions(this.app);
        onAuthStateChanged(this.auth, (user) => {
            if (!this.isAuthReady) this.isAuthReady = true;
            this.user = user;
            this.logView();
            if (this.onAuthReady) this.onAuthReady(this, user);
        });
    }

    public getApp(): FirebaseApp {
        return this.app;
    }

    public getAuth(): Auth {
        return this.auth;
    }

    public getFunctions(): Functions {
        return this.functions;
    }

    public getIsAuthReady(): boolean {
        return this.isAuthReady;
    }

    public getUser(): User | null {
        return this.user;
    }

    public getUID(): string {
        return this.user? this.user.uid : '';
    }

    public isLoggedIn(): boolean {
        return !!this.user;
    }

    public logout(): Promise<void> {
        return signOut(getAuth());
    }

    public setOnAuthReady(onAuthReady?: (app: AppManager, user: User | null) => void) {
        this.onAuthReady = onAuthReady;
        if (onAuthReady && this.isAuthReady) {
            onAuthReady(this, this.user);
        }
    }

    public getProfilePic(): string {
        return AppManager.DEFAULT_PROFILE_PICTURE; // FIXME remove
        if (!this.isLoggedIn) return AppManager.DEFAULT_PROFILE_PICTURE;
        return AppManager.addSizeToGoogleProfilePic(
            this.user?.photoURL || AppManager.DEFAULT_PROFILE_PICTURE);
    }

    public async getFirestoreUser(): Promise<AppUser | null> {
        if (!this.user) return null;
        const user = this.user;
        const docRef = doc(getFirestore(), 'users', user.uid);
        const userDoc = await getDoc(docRef);
        return userDoc.data() as AppUser;
    }

    public logView(): void {
        if (!this.user) return;

        const user = this.user;

        // Get a reference to the user
        const docRef = doc(getFirestore(), 'users', user.uid);
        getDoc(docRef).then((docSnap) => {

            // Create the updated data
            let data: any = {
                id: user.uid,
                name: user.displayName,
                email: user.email,
                lastPageView: serverTimestamp()
            };
            if (user.photoURL) data.photoURL = user.photoURL;

            // Returning user
            if (docSnap.exists()) {
                updateDoc(docRef, data);
            }

            // New user
            else {
                data.created = data.lastPageView;
                data.providerID = user.providerId;
                setDoc(docRef, data);
            }
        });
    }

    public static addSizeToGoogleProfilePic(url: string): string {
        if (url.indexOf('googleusercontent.com') !== -1 && url.indexOf('?') === -1) {
            return url + '?sz=150';
        }
        return url;
    }

    public static redirectToSignIn() {
        var l = window.location;
        var loginURL = l.protocol + '//' + l.host + AppManager.LOGIN_URL + '?to=' +
            encodeURIComponent(l.pathname);
        window.location.assign(loginURL);
    }
}