import React from 'react';
import Modal from 'react-modal';
import { Route, Routes } from 'react-router-dom';

import './App.css';

import { AppManager } from './data/AppManager';
import { ErrorPage } from './pages/ErrorPage';
import { HomePage } from './pages/HomePage';
import { LoadingPage } from './pages/LoadingPage';
import { NotLoggedInPage } from './pages/NotLoggedInPage';
import { ProfilePage } from './pages/ProfilePage';

// Initialize Firebase
const app = new AppManager();
console.log(app); // FIXME remove

Modal.setAppElement('#root');

function LoggedInContent() {
  return (
    <Routes>
      <Route path='/' element={<HomePage app={app} />} />
      <Route path='/profile' element={<ProfilePage app={app} />} />
    </Routes>
  );
}

class App extends React.Component<{}, {
  isAuthReady: boolean,
  isReady: boolean,
  error?: any
}> {

  constructor(props: any) {
      super(props);
      this.state = {
          isAuthReady: false,
          isReady: false
      };
  }

  componentDidMount() {
      app.setOnAuthReady(() => {
          if (!app.isLoggedIn()) { // not logged in
              AppManager.redirectToSignIn();
          }
          this.setState({isAuthReady: true, isReady: app.isLoggedIn()});
      });
  }

  render() {

      // Determine content
      let content = <LoggedInContent />;
      const s = this.state;
      if (!s.isReady) { // not ready; show loading page
          content = <LoadingPage app={app} />;
      } else if (!app.isLoggedIn()) { // not logged in (user should never see this, but just in case)
          content = <NotLoggedInPage app={app} />;
      } else if (s.isReady && s.error) {
          content = <ErrorPage app={app}>{'' + s.error}</ErrorPage>
      }
      return (
          <div className='app-container'>
              {content}
          </div>
      );
  }
}

export default App;
