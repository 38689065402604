import React from 'react';
import { Link } from 'react-router-dom';
import { Icon } from './Icon';
import './NavTitle.css';

export class NavTitle extends React.Component<{
    children?: any,
    className?: string,
    link?: string,
    onClick?: (e: any) => void
}, {}> {
    render() {
        let c = 'nav-title';
        if (this.props.className) {
            c += ' ' + this.props.className;
        }
        const content = <><Icon>arrow_back_ios</Icon>{this.props.children}</>;
        return (
            this.props.link? <Link className={c} to={this.props.link} onClick={this.props.onClick}>{content}</Link>
            : <button className={c} onClick={this.props.onClick}>{content}</button>
        );
    }
}